import classNames from 'classnames';
import { ChangeEvent, FocusEventHandler, KeyboardEvent } from 'react';
import { ONE_NUMBER_PATTERN, extractNumberFromInputId } from '../../helpers';
import styles from './SafetyCodeInputItem.css';

export type SafetyCodeInputItemProps = {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
  id: string;
  value: string[];
  inputRef: (elt: HTMLInputElement) => HTMLInputElement;
  onFocus: FocusEventHandler<HTMLInputElement>;
};

function SafetyCodeInputItem({
  onChange,
  id,
  value,
  onKeyDown,
  inputRef,
  onFocus,
}: SafetyCodeInputItemProps): JSX.Element {
  const inputValue = value[extractNumberFromInputId(id)] || '';
  return (
    <div
      className={classNames(styles.SafetyCodeInputItem, {
        [styles['SafetyCodeInputItem--filled']]: inputValue.length,
      })}
    >
      <input
        type="number" /* We are using a number input instead of a password one to avoid triggering the Chrome password storage feature */
        id={id}
        name={id}
        pattern={ONE_NUMBER_PATTERN}
        value={inputValue}
        onChange={onChange}
        onKeyDown={onKeyDown}
        autoComplete="off"
        onFocus={onFocus}
        onBlur={onFocus}
        className={styles.SafetyCodeInputItem}
        size={1}
        ref={inputRef}
        maxLength={1}
        inputMode="numeric"
      />
    </div>
  );
}

export default SafetyCodeInputItem;
