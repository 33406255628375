import { OfferZone } from '@canalplus/sdk-core';

interface IOfferZoneConfig {
  offerZones: {
    [key in OfferZone]?: {
      phoneNumber: string;
    };
  };
}

const offerZoneConfig: IOfferZoneConfig = {
  offerZones: {
    [OfferZone.toDefine]: { phoneNumber: '' },
    [OfferZone.cpant]: {
      phoneNumber: '26 53 30',
    },
    [OfferZone.cpche]: {
      phoneNumber: '021 555 32 32',
    },
    [OfferZone.cpreu]: {
      phoneNumber: '26 53 30',
    },
    [OfferZone.cpmdg]: {
      phoneNumber: '26 53 30',
    },
    [OfferZone.cpncl]: {
      phoneNumber: '26 53 30',
    },
    [OfferZone.cppol]: {
      phoneNumber: '26 53 30',
    },
    [OfferZone.cpfra]: {
      phoneNumber: '0892393910',
    },
    [OfferZone.cpafr]: {
      phoneNumber: '26 53 30',
    },
    [OfferZone.cpeng]: {
      phoneNumber: '26 53 30',
    },
    [OfferZone.cpoth]: {
      phoneNumber: '26 53 30',
    },
    [OfferZone.cpmus]: {
      phoneNumber: '26 53 30',
    },
    [OfferZone.tiita]: {
      phoneNumber: '26 53 30',
    },
    [OfferZone.cbant]: {
      phoneNumber: '26 53 30',
    },
    [OfferZone.cbreu]: {
      phoneNumber: '26 53 30',
    },
    [OfferZone.cppyf]: {
      phoneNumber: '26 53 30',
    },
    [OfferZone.cpeth]: {
      phoneNumber: '92 29',
    },
    [OfferZone.cpchd]: {
      phoneNumber: '021 555 32 32',
    },
  },
};

export default offerZoneConfig;
